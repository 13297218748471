
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import { getVideoList, video_play, getModelInfo } from "@/api/index.js";
import Sidebar from "@/components/Sidebar/index.vue";
import triangleIcon from "assets/images/triangle_icon.png";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  setup() {
    interface props {
      type?: any;
      menuList?: Array<any>;
      list?: Array<any>;
      keyword?: string;
      total?: any;
      page?: any;
      limit?: any;
      isShow?: boolean;
      currentItem?: { title: any; url: any; img: any; hits: any; id: any };
    }
    let state: props = reactive({
      type: "1",
      menuList: [
        // {
        //   name: "操作视频",
        //   type: "1",
        // },
        {
          name: "培训视频",
          type: "2",
        },
      ],
      list: [],
      keyword: "",
      total: 0,
      page: 1,
      limit: 10,
      isShow: false,
      currentItem: { title: "", url: "", img: "", hits: "0", id: "" },
    });

    const activeIndex = computed(() => {
      let index = 0;
      state.menuList?.map((r, i) => {
        if (r.type == state.type) {
          index = i;
        }
      });
      return index;
    });

    const $route = useRoute();
    const $router = useRouter();
    watch(
      () => $route.query,
      (newQuery, oldQuery) => {
        if (newQuery?.type !== oldQuery?.type) {
          if ($route.query.type) {
            state.type = $route.query.type;
          }
        }
      },
      { immediate: true }
    );

    const sidebarclick = (item: any) => {
      state.type = item.type;
      $router.push({ query: ($route.query, { type: item.type }) });
      state.isShow = false;
      state.keyword = "";
    };

    const videoListFn = async (type: any) => {
      const params = {
        type,
        keyword: state.keyword,
        page: state.page,
        limit: state.limit,
      };
      return await getVideoList(params).then((res: any) => {
        if (res.code == "0") {
          return res;
        }
      });
    };

    const init = () => {
      videoListFn(state.type).then((res) => {
        state.list = res.data || [];
        state.total = Number(res.count);
      });
    };

    const currentChange = (page: any) => {
      state.page = page;
      init();
    };

    init(); // 获取数据

    watch(
      () => state.type,
      (type, prevType) => {
        if (type != prevType) {
          init();
        }
      }
    );

    const openDetails = (item: any) => {
      state.isShow = true;
      state.currentItem = item;
    };

    const videoPlay = (e: any) => {
      const params = {
        id: state.currentItem?.id,
      };
      video_play(params);
    };

    return {
      ...toRefs(state),
      triangleIcon,
      activeIndex,
      sidebarclick,
      init,
      currentChange,
      openDetails,
      videoPlay,
    };
  },
  components: { Sidebar },
});
